<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
      <p class="lead">
      Block Me Registration
      </p>

      <p>
        If you do not wish to receive future emails from our sales personnel, please complete the "Block Me" registration. Submit the email address below, and a verification email will be sent to you. After verifying, your email address will be added to our block list.
      </p>

      <div v-if="error" class="alert alert-danger">{{error}}</div>

      <div v-if="message" class="alert alert-success">
        <div class="lead"><p>Verify your email address</p></div>
        <p class="fw-bold">{{message}}</p>
        <p>If you do not receive the verification email, please check your
          "Spam" or "Bulk Mail" folder or contact <a href="mailto:webmaster@malabs.com">webmaster@malabs.com</a>
          for assistance.
        </p>
      </div>
      

      <form class="row g-3">
        <div class="col-md-6" :class="{'alert alert-danger': data.email.error}">
          <label class="form-label">Email Address <span class="text-danger">*</span></label>
          <div v-if="data.email.error">{{data.email.message}}</div>
          <input type="email" class="form-control" v-model="data.email.input" maxlength="100">
        </div>
        
        <div class="col-md-12">
          <div :class="{'alert alert-danger': data.authorization.error}">
            <div v-if="data.authorization.error">This field is required</div>
            <input class="form-check-input" type="checkbox" v-model="data.authorization.input">
            <label class="form-check-label" for="gridCheck">&nbsp;
              I am an authorized user of this email address.
            </label>
          </div>
        </div>

        <p class="mt-3">
        <i class="bi bi-info-circle-fill icon me-2"></i><a class="anchor" @click="handleInfo()">Why do I continue to receive sales emails after completing the Block Me Registration? </a>
        <div class="border p-4 bg-light shadow rounded" id="info">
          <p>
            If you have any additional email accounts, alias emails, or addresses that are forwarded to your email account, you may receive emails from our sales personnel even after you have completed the "Block Me" registration process.
          </p>

          <p>
            In such an event, please register those additional email accounts as part of our "Block Me" Registration process as well.
          </p>

          <p>For example:</p>
          <ul>
          <li>sample.user@yourdomain.com (email forwarded from an educational institution)</li>
          <li>info@yourdomain.com (forwarded email)</li>
          <li>sales@yourdomain.com (group mailing list)</li>
          <li>john.doe2@yourdomain.com (additional email)</li>
          <li>john.resigned@yourdomain.com (forwarded email from employees who left the company)</li>
          <li>user@yourdomain.com (a different domain address that is forwarded to your current email address)</li>
          </ul>
          <p>Please note that it may take up to 1-2 business days for the "Block Me" registration to become effective.</p>

          
        </div>
        </p>

        <div class="col-12">
            <button v-if="waiting" class="btn btn-primary btn" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Submit
              </button>
            <button v-else type="submit" class="btn btn-primary btn" @click.prevent="handleSubmit">Submit</button>
          </div>
      </form>

      
      <p class="mt-5">If you receive emails from our sales personnel after you have registered all email accounts, please send us a note so that we can address the problems to your satisfaction.</p>
        
      <p><router-link :to="{name: 'BlockMeReport'}">Send us a note</router-link> if you have any problems with the Block Me registration.</p>

      <p>To unblock your email, click <router-link :to="{name: 'UnblockMe'}">here</router-link>.</p>
  </div>

  <div id="google_recaptcha"></div>
</template>

<script>
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref, onMounted } from 'vue'
import $ from 'jquery'
import { insertReCaptchaScript, handleReCaptcha, checkEmail } from '../../utils/common'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'BlockMe',
  components: { BreadcrumbPage },
  setup() {
    const waiting = ref(false)
    const error = ref(null)
    const message = ref(null)
    const data = ref({
      email: {input: null, error: false, message: ""},
      authorization: {input: true, error: false},
    })
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Home", link: "Home"})
    breadcrumb_links.value.push({text: "Block Me Registration", link: ""})

    //add script for google reCaptcha to template
    onMounted(() => {
        insertReCaptchaScript("google_recaptcha")
    })

    function validate() {
      let valid = true

      if (data.value.email.input != "" && data.value.email.input != null) {
        if (checkEmail(data.value.email.input) == true)
          data.value.email.error = false
        else {
          valid = false 
          data.value.email.error = true
          data.value.email.message = "The email address is invalid."
        }
      }
      else {
        valid = false 
        data.value.email.error = true
        data.value.email.message = "The email address is required."
      }

      if (data.value.authorization.input == true) {
        data.value.authorization.error = false
      }
      else {
        valid = false 
        data.value.authorization.error = true
      }

      return valid
    }

    const handleSubmit = async () => {
      waiting.value = true
      error.value = null
      message.value = null

      let result = await handleReCaptcha(grecaptcha)
      if (result["is_human"] == true) {
        //validate the form
        if (validate() == false) {
          waiting.value = false
          return 0
        }
        
        let payload = {
          email: data.value.email.input,
        }
        
        data.value.email.input = null
        
        getAPI.post('/account/blockme/register/block/', payload)
        .then((response) => {
          let res = response.data

          message.value = res["message"]
          
          waiting.value = false
        })
        .catch(err => {
          error.value = err.response.data.message
          waiting.value = false
        })
      }
      else {
        //if not pass google reCaptcha, show the error
        waiting.value = false
        error.value = result["message"]
      }

    }

    //show or hide the info explaination layer
    const handleInfo = () => {
      $("#info").slideToggle("slow")
    }

    return {
      waiting,
      error,
      message,
      data,
      handleSubmit,
      handleInfo,
      breadcrumb_links
    }

  }
}
</script>

<style scoped>
.lead {
  font-size: 26px;
  font-family: "Roboto";
  font-weight: 600;
  color: #555555;
}
.anchor {
  cursor: pointer;
  color:#000;
}
.anchor:hover {
  color:cornflowerblue;
}
#info {
  display:none;
}
</style>